import React from 'react';

const Footer = () => {
  return (
    <footer className="c-row c-row--alpha c-row-footer u-bgcolor-neutral-x-light" id="contact">
      <div className="o-container o-container--sm">
        <div>
          <ul className="o-list o-list--horizontal c-social__list u-position-center">
            <li className="o-list__item c-social__list-item">
                <a href="https://www.linkedin.com/in/dries-standaert-67635383/" target="_blank" title="LinkedIn" rel="noreferrer" className="c-social__link c-social__link--linkedin">LinkedIn</a>
            </li>
            <li className="o-list__item c-social__list-item u-mr-beta">
                <a href="http://codepen.io/driesstandaert/" target="_blank" title="Codepen" rel="noreferrer" className="c-social__link c-social__link--codepen">Codepen</a>
            </li>
            <li className="o-list__item c-social__list-item u-mr-beta">
            <a href="mailto:dries.standaert@gmail.com" title="dries.standaert@gmail.com" className="c-social__link c-social__link--mail">mail</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;